import { Link } from 'gatsby';
import React from 'react';
import $ from 'jquery';

import logo from '../assets/img/THNG-logo.svg';

export default function Logo() {
	const handleLinkClick = React.useCallback((event) => {
		if (typeof window === 'undefined') {
			event.preventDefault();
			return;
		}

		document.querySelector('body').classList.remove('mlg_drawer-open');

		if (window.location.pathname === '/') {
			$('html,body').animate(
				{
					scrollTop: 0 + 'px',
				},
				800,
				'swing'
			);
		}
	}, []);

	return (
		<div className="mlg_site-logo">
			<Link to="/" onClick={handleLinkClick}>
				<img className="mlg_sl-image" src={logo} alt="THNG Logo" />
			</Link>
		</div>
	);
}

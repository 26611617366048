import React from 'react';
import { Container } from 'react-bootstrap';

// Components
import Logo from '../components/logo';
import Navigation from '../components/navigation';
import Drawer from '../components/drawer';

// Icons
import burger_menu_icon from '../assets/icons/burger-menu-icon.svg';
import { Link } from 'gatsby';

export default function Header() {
	const openDrawer = React.useCallback(() => {
		document.querySelector('body').classList.add('mlg_drawer-open');
	}, []);

	const idPath = React.useCallback((id) => {
		if (typeof window === 'undefined') {
			return `/${id}`;
		}

		const path = window.location.pathname;

		if (
			path.startsWith('/impressum') ||
			path.startsWith('/datenschutzerklarung')
		) {
			return `/${id}`;
		} else {
			return id;
		}
	}, []);

	return (
		<header className="mlg_site-header">
			<div className="mlg_sh-container">
				<Logo />

				<Container>
					<Navigation />
				</Container>

				<div className="d-flex">
					<div className="mlg_sh-btn mlg_btn mlg_b-outline">
						<Link to={idPath('#kontakt')}>
							Anfrage stellen
						</Link>
					</div>

					<button className="mlg_sh-nav-toggle" type="button" onClick={openDrawer}>
						<img src={burger_menu_icon} alt="" />
					</button>
				</div>
			</div>

			<Drawer />
		</header>
	);
}

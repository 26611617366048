import React from 'react';
import Logo from '../components/logo';
import Navigation from '../components/navigation';

// Icons
import close_menu_icon from '../assets/icons/close-menu-icon.svg';

export default function Drawer() {
	const closeDrawer = React.useCallback(() => {
		document.querySelector('body').classList.remove('mlg_drawer-open');
	}, []);

	return (
		<>
			<div className="mlg_drawer-overlay" onClick={closeDrawer}></div>

			<div id="mlg_drawer" className="mlg_drawer">
				<div className="mlg_d-container">
					<div className="mlg_d-top">
						<Logo />

						<button className="mlg_d-close" onClick={closeDrawer}>
							<img src={close_menu_icon} alt="Close Menu" />
						</button>
					</div>

					<Navigation className="mlg_d-navigation" />
				</div>
			</div>
		</>
	);
}

import React from 'react';
import { Link } from 'gatsby';
import $ from 'jquery';

export default function Navigation(props) {
	const referencePath = React.useCallback(() => {
		if (typeof window === 'undefined') {
			return '/#referenzen';
		}

		const path = window.location.pathname;

		if (path.startsWith('/kommune') || path.startsWith('/#') || path === '/') {
			return '#referenzen';
		} else {
			return '/#referenzen';
		}
	}, []);

	const idPath = React.useCallback((id) => {
		if (typeof window === 'undefined') {
			return `/${id}`;
		}

		const path = window.location.pathname;

		if (
			path.startsWith('/uber-uns') ||
			path.startsWith('/impressum') ||
			path.startsWith('/datenschutzerklarung')
		) {
			return `/${id}`;
		} else {
			return id;
		}
	}, []);

	const handleReferenceClick = React.useCallback((event) => {
		if (typeof window === 'undefined') {
			event.preventDefault();
			return;
		}

		const path = window.location.pathname;

		if (!path.startsWith('/kommune') && !path.startsWith('/#') && path !== '/') {
			event.preventDefault();
		} else {
			document.querySelector('body').classList.remove('mlg_drawer-open');
		}
	}, []);

	const handleLinkClick = React.useCallback((event) => {
		if (typeof window === 'undefined') {
			event.preventDefault();
			return;
		}

		document.querySelector('body').classList.remove('mlg_drawer-open');

		const target = event.target.getAttribute('href').split('/');
		const path = window.location.pathname.split('/');

		if (target[1] === path[1] && target[2] === undefined) {
			$('html,body').animate(
				{
					scrollTop: 0 + 'px',
				},
				800,
				'swing'
			);
		}
	}, []);

	return (
		<nav className={'mlg_site-navigation' + (props.className ? ' ' + props.className : '')}>
			<ul>
				<li>
					<Link to={idPath('#vorteile')} onClick={handleLinkClick}>
						Vorteile
					</Link>
				</li>
				<li>
					<Link to={idPath('#wie-funktioniert-THNG')} onClick={handleLinkClick}>
						Wie funktioniert THNG?
					</Link>
				</li>
				<li>
					<Link to="/#kurz-erklaert" onClick={handleLinkClick}>
						Kurz erklärt
					</Link>
				</li>
				<li>
					<Link to={idPath('#sicherheit')} onClick={handleLinkClick}>
						Sicherheit
					</Link>
				</li>
				<li>
					<Link to={referencePath()} onClick={handleReferenceClick}>
						Referenzen
					</Link>
					<ul className="sub-menu">
						<li>
							<Link to="/kommune" onClick={handleLinkClick}>
								Kommune
							</Link>
						</li>
						<li>
							<Link to="/unternehmen" onClick={handleLinkClick}>
								Unternehmen
							</Link>
						</li>
						<li>
							<Link to="/verein" onClick={handleLinkClick}>
								Verein
							</Link>
						</li>
					</ul>
				</li>
				<li>
					<Link to="/#service" onClick={handleLinkClick}>
						Service
					</Link>
				</li>
				{/* <li>
					<Link to={idPath('#preise')} onClick={handleLinkClick}>
						Preise
					</Link>
				</li> */}
				<li>
					<Link to="/uber-uns" onClick={handleLinkClick}>
						Über uns
					</Link>
				</li>
				<li>
					<Link to="/#faq" onClick={handleLinkClick}>
						FAQ
					</Link>
				</li>
			</ul>
		</nav>
	);
}
